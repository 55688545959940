@import 'elements_v2/_font/inter.css';

html {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  scroll-behavior: smooth;
  font-family: 'Inter', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
body.draggingWithOverlay {
  cursor: none !important;
}
.Pickit--isNative[data-os='Windows'] {
  &::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 4px; /* Mostly for horizontal scrollbars */
  }
  &::-webkit-scrollbar-thumb {
    /* Foreground */
    background: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: #dadada;
  }
  &:hover::-webkit-scrollbar {
    display: block; /* for Chrome, Safari, and Opera */
  }
  &::-webkit-scrollbar-track {
    /* Background */
    background: transparent;
  }
}
textarea {
  font-family: 'Inter', system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.doka-image-editor {
  --color-background: 37, 37, 37;
  --color-foreground: 255, 255, 255;
  z-index: 99999999999999;
}
#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    width: 99%;
  }
  @supports (-ms-accelerator: true) {
    /* Edge 12+ CSS */
    width: 99%;
  }
  @supports (-ms-ime-align: auto) {
    /* Edge 16+ CSS */
    width: 99%;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scrollable {
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 5px; /* Mostly for vertical scrollbars */
    height: 4px; /* Mostly for horizontal scrollbars */
  }
  &::-webkit-scrollbar-thumb {
    /* Foreground */
    background: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: #dadada;
  }
  &:hover::-webkit-scrollbar {
    display: block; /* for Chrome, Safari, and Opera */
  }
  &::-webkit-scrollbar-track {
    /* Background */
    background: transparent;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input,
textarea {
}

.button-link {
  border: none;
  background: none;
  font-size: 13px;
  cursor: pointer;
  transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.button-link:hover {
  opacity: 0.7;
  transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body {
  margin: 0;
  padding: 0;
  background: #f7f7f7;
  overflow: auto;
  height: 100%;

  &.Pickit--isDarkMode {
    background: #252525;
  }

  &.Pickit--isNative[system-color-scheme='dark']:not(.Pickit--isDarkMode) {
    background: #fafafa;
  }

  &.Pickit--isNative[system-color-scheme='light'].Pickit--isDarkMode[window-transparent="true"] {
    background: #424242;
  }
  &.Pickit--isNative[system-color-scheme='dark']:not([window-transparent="true"]) {
    background: #252525;
  }
  &.Pickit--isNative[system-color-scheme='light'][window-transparent="true"] {
    background: transparent;
  }
  &.Pickit--isNative[system-color-scheme='dark'][window-transparent="true"] {
    background: transparent;
  }
}

#hs-eu-cookie-confirmation {
  display: none;
}

body.no-scroll {
  overflow: hidden;
}

img {
  max-width: 100%;
}

textarea {
}

.full-width {
  width: 100%;
}

.block-center {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.invisible {
  width: 0;
  height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.expanded {
  width: 100%;
}

.flex {
  display: flex;
}

.bold {
  font-weight: bold;
}

.line-throug {
  text-decoration: line-through;
}

.checkbox-label {
  font-size: 0.813rem;
  color: black;
  font-weight: normal;
}

.hidden {
  visibility: hidden;
}

@keyframes showSplash {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.App__splash {
  width: 100vw;
  height: 100vh;
  background: #f7f7f7;
  animation: showSplash 0.25s forwards;
  animation-delay: 2s;
  z-index: 9999;
  opacity: 0;
  padding-top: 15vh;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
}

.ErrorPage {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url('https://cdn.pickit.com/images/backgrounds/error-page.jpg');
  background-position: center;
  background-size: cover;

  @media only screen and (min-width: 900px) {
    background-image: url('https://cdn.pickit.com/images/backgrounds/error-page-desktop.jpg');
  }

  &__overlay {
    width: 100%;
    height: 100%;
    padding: 15vh 15px;
    background: rgba(0, 0, 0, 0.5);
  }

  &__content {
    width: 100%;
    max-width: 260px;
    margin: auto;

    h1 {
      font-weight: 800;
      font-size: 42px;
      margin: 0;
      color: #fff;
    }
    p {
      color: #fff;
      font-weight: 500;
      line-height: 1.6;
      margin: 15px 0 25px 0;
    }
  }
}

@keyframes HighlightDot {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15% {
    transform: scale(6);
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.HighlightDot:before {
  width: 100%;
  height: 100%;
  background: #ff0091;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
  animation: HighlightDot 8s infinite;
}
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@keyframes badgePulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.8);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
